import { Dispatch, SetStateAction, useState } from "react";
import Slider from "react-slick";
import Router from "next/router";

import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";

import LoginModal from "../../../../../containers/LoginModal";

import { NextArrow, PrevArrow } from "../Arrows";
import Styled from "../index.styles";
import RateItem from "./RateItem";
import useGetHeroSectionRateList from "./useGetHeroSectionRateList";

function DiscountRateContent({
  setAbTestType,
}: {
  setAbTestType: Dispatch<SetStateAction<string>>;
}) {
  const { isMobile } = useCheckIsMobile();

  /**
   * login 성공후 order page의 url을 특정해줘야하므로 boolean이 아닌 port type을 저장
   * - loginModalOrderPageType이 있으면 modal이 표시되고, undefined면 모달이 사라지는 방식.
   * - 이 상태를 RateItem컴포넌트에서 관리 하지 않는 이유
   *   - RateItem은 Slider 라이브러리 내부에 있어서 여기서 modal이 표시되면 Slider 레이아웃이 깨짐.
   */
  const [loginModalOrderPageType, setLoginModalOrderPageType] = useState<
    "sea" | "air"
  >();

  const moveToOrderPage = () => {
    if (!loginModalOrderPageType) return;

    const typeLabel = loginModalOrderPageType === "sea" ? "ocean" : "air";
    Router.push(`/forwarding/${typeLabel}/order`);
  };

  const sliderSettings = (() => {
    {
      return {
        dots: false,
        infinite: false,
        slidesToShow: isMobile ? 2 : 4,
        slidesToScroll: 1,
        autoplay: false,
        easing: "ease-in",
        rows: 1,
        arrows: !isMobile,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
      };
    }
  })();

  const { rateList } = useGetHeroSectionRateList({ setAbTestType });

  if (rateList.length === 0) {
    return null;
  }

  return (
    <>
      <Styled.discountRateContent>
        <Styled.rateList>
          <Slider {...sliderSettings}>
            {rateList.map((v) => {
              if (!v) return null;

              return (
                <RateItem
                  data={v}
                  key={v.scheduleId}
                  showLoginModal={() => {
                    setLoginModalOrderPageType(
                      v.freightType === "AIR" ? "air" : "sea"
                    );
                  }}
                />
              );
            })}

            {/* 모바일에서 마지막 섹션 옆의 여백을 위해, 빈 슬라이드 추가 */}
            {isMobile && <div style={{ width: "290px" }}></div>}
          </Slider>
        </Styled.rateList>

        <p className="desc">
          *위 운임은 확정운임이 아니며, 물류 시황이나 구체적인 운송계획에 따라
          변동될 수 있습니다.
        </p>
      </Styled.discountRateContent>

      <LoginModal
        active={!!loginModalOrderPageType}
        setIsVisibleModal={(v) => {
          if (!v) {
            setLoginModalOrderPageType(undefined);
          }
        }}
        backToAfterRegister={""}
        afterLoginCallback={moveToOrderPage}
      />
    </>
  );
}

export default DiscountRateContent;
