import { Dispatch, SetStateAction, useMemo } from "react";

import SCHEDULE_QUERY from "@sellernote/_shared/src/queries/forwarding/SCHEDULE_QUERY";
import { HeroSectionSchedule } from "@sellernote/_shared/src/types/forwarding/schedule";
import { getRandomSortedArray } from "@sellernote/_shared/src/utils/common/etc";

export default function useGetHeroSectionRateList({
  setAbTestType,
}: {
  setAbTestType: Dispatch<SetStateAction<string>>;
}) {
  const { data: heroSectionScheduleList } =
    SCHEDULE_QUERY.useGetHeroSectionScheduleList({
      onError: () => {
        setAbTestType("A");
      },
    });

  const LCLFixedRateList = useMemo(
    () =>
      (heroSectionScheduleList ?? []).filter((v) => v.freightType === "LCL"),
    [heroSectionScheduleList]
  );

  const FCLCNFixedLateList = useMemo(
    () =>
      (heroSectionScheduleList ?? []).filter(
        (v) => v.freightType === "FCL" && v.pol === "CNSHG"
      ),
    [heroSectionScheduleList]
  );

  const FCLCNRateListExcludingCNSHGtoKRPUS = useMemo(
    () =>
      (heroSectionScheduleList ?? []).filter(
        (v) =>
          v.freightType === "FCL" && v.polCountry === "CN" && v.pol !== "CNSHG"
      ),
    [heroSectionScheduleList]
  );

  const FCLVNOrDERateList = useMemo(
    () =>
      (heroSectionScheduleList ?? []).filter(
        (v) =>
          v.freightType === "FCL" &&
          (v.polCountry === "VN" || v.polCountry === "DE")
      ),
    [heroSectionScheduleList]
  );

  const AIRRateList = useMemo(
    () =>
      (heroSectionScheduleList ?? []).filter((v) => v.freightType === "AIR"),
    [heroSectionScheduleList]
  );

  const randomMixCNRateListExcludingCNSHGtoKRPUS = useMemo(
    () => getRandomSortedArray(FCLCNRateListExcludingCNSHGtoKRPUS),
    [FCLCNRateListExcludingCNSHGtoKRPUS]
  );

  const randomMixVNOrDERateList = useMemo(
    () => getRandomSortedArray(FCLVNOrDERateList),
    [FCLVNOrDERateList]
  );

  const randomMixAIRRateList = useMemo(
    () => getRandomSortedArray(AIRRateList),
    [AIRRateList]
  );

  // 랜덤으로 섞인 운임가 리스트.
  // 정책 참고: https://sellernotehq.slack.com/archives/C06R8SV5GC8/p1718952562018369?thread_ts=1718946050.226259&cid=C06R8SV5GC8
  const rateList: HeroSectionSchedule[] = [
    ...LCLFixedRateList,
    ...FCLCNFixedLateList,
    randomMixVNOrDERateList[0],
    randomMixAIRRateList[0],
    randomMixCNRateListExcludingCNSHGtoKRPUS[0],
    randomMixCNRateListExcludingCNSHGtoKRPUS[1],
    randomMixVNOrDERateList[1],
    randomMixAIRRateList[1],
  ];

  return { rateList: heroSectionScheduleList ? rateList : [] };
}
