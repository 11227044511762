import React, { Dispatch, SetStateAction } from "react";

import DiscountRateContent from "./DiscountRateContent";
import TitleAndCta from "./TitleAndCta";
import Styled from "./index.styles";

export default function MainSectionIntroB({
  setAbTestType,
}: {
  setAbTestType: Dispatch<SetStateAction<string>>;
}) {
  return (
    <Styled.container>
      <TitleAndCta />

      <DiscountRateContent setAbTestType={setAbTestType} />
    </Styled.container>
  );
}
