import React from "react";
import dynamic from "next/dynamic";
import Image from "next/image";

import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";

import ScheduleSearchBar from "../../../../containers/ScheduleSearchBar";

import Styled from "./index.styles";

const Video = dynamic(() => import("./Video"), {
  ssr: false,
});

function TitleAndCta() {
  const { isMobile } = useCheckIsMobile();

  return (
    <Styled.sectionIntroWrapper>
      <Styled.sectionIntro>
        <Styled.mainDescription>
          <h2>
            <span className="line">투명한 일정과&nbsp;</span>
            <span className="line">합리적인 운임으로</span>
            <br className="only-mobile" /> 물류를 쉽게
          </h2>

          <p className="desc">
            쉽다 포워딩 전문가가 직접 제공하는
            <br className="only-mobile" /> 디지털 포워딩 서비스를 지금
            경험해보세요!
          </p>
        </Styled.mainDescription>

        <Styled.ctaContainer>
          <ScheduleSearchBar />
        </Styled.ctaContainer>
      </Styled.sectionIntro>

      {!isMobile && <Video />}

      {isMobile && (
        <>
          <Styled.backgroundImage>
            <Image
              src={"/assets/images/main/intro-video-mobile-test-type-b.webp"}
              alt="background-image"
              layout="fill"
              objectFit="cover"
              placeholder="blur"
              blurDataURL="/assets/images/main/intro-video-mobile-blur-data.png"
              priority
            />
          </Styled.backgroundImage>
        </>
      )}
    </Styled.sectionIntroWrapper>
  );
}

export default TitleAndCta;
