import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";

const formatPort = ({
  portKrName,
  code,
}: {
  portKrName: string | undefined;
  code: string | undefined;
}) => `${replaceEmptyToDash(portKrName)} (${replaceEmptyToDash(code)})`;

export { formatPort };
