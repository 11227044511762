import React, { useEffect } from "react";

import { APP_REGION } from "@sellernote/_shared/src/constants";
import { LOCAL_STORAGE_KEY_FOR_SHIPDA_HERO_A_B_TEST_KEY } from "@sellernote/_shared/src/constants/common/abTest";
import { addEventToGTMDataLayer } from "@sellernote/_shared/src/utils/common/gtm";

import MainSectionIntroA from "./SectionIntroA";
import MainSectionIntroB from "./SectionIntroB";
import useLocalStorageByString from "./useLocalStorageByString";

/**
 * intro section A/B 테스트는 "KR" 지역에서만 사용함
 */
const USES_AB_TEST = APP_REGION === "KR";

export default function MainSectionIntro() {
  const [abTestType, setAbTestType] = useLocalStorageByString(
    LOCAL_STORAGE_KEY_FOR_SHIPDA_HERO_A_B_TEST_KEY,
    ""
  );

  useEffect(() => {
    // A/B 테스트를 하지 않는 REGION 은 제외.
    if (!USES_AB_TEST) return;

    // 이미 값이 있는 경우에는 할당하지 않음. 사용자에게 일관된 경험 제공.
    if (abTestType) return;

    const randomTestType = Math.random() < 0.5 ? "A" : "B";

    setAbTestType(randomTestType);

    // gtm 이벤트도 최초 노출 시에만 발생.
    addEventToGTMDataLayer({
      event: `hero_ab_${randomTestType}`,
    });
  }, [abTestType, setAbTestType]);

  // A/B 테스트를 하지 않는 지역은 기존 A Section 노출
  if (!USES_AB_TEST) return <MainSectionIntroA />;

  return (
    <>
      {abTestType === "A" && <MainSectionIntroA />}
      {abTestType === "B" && (
        <MainSectionIntroB setAbTestType={setAbTestType} />
      )}
    </>
  );
}
