import { useRecoilValue } from "recoil";

import USER_QUERY from "@sellernote/_shared/src/queries/forwarding/USER_QUERY";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import { FreightType } from "@sellernote/_shared/src/types/common/common";

export default function useCheckCanFirstDiscount(
  freightType: FreightType | undefined
) {
  const isLoggedIn = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const { data: userInfo } = USER_QUERY.useGetUserInfo({
    enabled: Boolean(isLoggedIn),
  });

  const isNotLoggedIn = !isLoggedIn;
  const hasNoOrderHistory = isLoggedIn && userInfo?.inProgressBidCount === 0;
  const isNotLCL = freightType !== "LCL";

  return Boolean((isNotLoggedIn || hasNoOrderHistory) && isNotLCL);
}
